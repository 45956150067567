.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link, .navbar-brand {
  cursor: pointer; /* Ensures the pointer cursor appears when hovering */
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  font-size: 3rem;
  color: darkgray;
  display: none; /* Initially hidden */
}

.scrollTop:hover {
  color: grey;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  overflow: hidden;
  margin: 0 auto;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  min-width: 33.33%; /* Show 3 images at a time */
  margin: 0 10px;
  object-fit: cover;
}

/* CSS for fade-in animation 
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}
  */

  .product {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .product.in-view {
    opacity: 1;
    transform: translateY(0);
  }



@media (max-width: 992px) {
  .d-flex.flex-wrap {
    flex-direction: column; /* Stack products vertically */
    align-items: center; /* Center align each product */
  }

  .product {
    max-width: 100%; /* Full width for each product */
    margin: 20px 0; /* Add vertical spacing between products */
  }
}


@media (max-width: 991px) {
  .carousel-image {
    flex: 0 0 calc(50% - 20px); /* For two images below 992px */
  }
}


@media (max-width: 768px) {
  /* Target only the specific container you want to adjust */
  .product-container .d-flex {
    flex-direction: column; /* Stack items on top of each other */
    align-items: center; /* Center align items */
  }

  .product {
    width: 100%; /* Ensure each product takes full width */
    margin: 10px 0; /* Add vertical spacing between products */
  }

  .product img {
    width: 100%; /* Ensure image takes full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
  }
}

@media (max-width: 576px) {
  .carousel-container .carousel-image {
    flex: 0 0 100%; /* For one image below 576px */
  }

  .product-container .d-flex {
    flex-direction: column; /* Stack items on top of each other */
    align-items: center; /* Center align items */
  }

  .product {
    width: 100%; /* Ensure each product takes full width */
    margin: 10px 0; /* Add vertical spacing between products */
  }
}




.product-img {
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.product-img:hover {
  transform: scale(1.1); /* Zoom the image by 10% */
}

/* Preloader Styles */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* Emoji Loader */
.emoji-loader {
  font-size: 3rem; /* Make emojis larger */
}

/* Loading Text */
.loading-text {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #007bff;
}

.footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 1rem;
}

.footer .social-icons {
  margin-top: 0.5rem;
}

.footer .icon {
  color: white;
  margin: 0 0.5rem;
  text-decoration: none;
}

.footer .icon:hover {
  color: gray;
}

/* Pre loader Option 2 (more basic approach)
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.loading-text {
  margin-top: 10px;  Add some spacing between the spinner and the text 
  font-size: 1.2rem;
  color: #007bff;  Matches the primary spinner color 
}
*/
